import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage
            src={mobileAbout}
            w="100%"
            pv="5px"
            alt="Tokyo Joe Sushi Japanese Restaurant About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto">
          <CFView column justifyEnd alignCenter w="100%" maxWidth="1400px">
            <CFImage src={about} w="100%" alt="Food Gallery" m="0 auto" />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
